import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

import ExternalLink from '@starbucks-web/pattern-library/lib/components/external-link';

import H1 from 'shared/app/components/h1';
import { rewardsMessages } from 'shared/app/messages/rewards';
import { marriottJoinLinkSelector } from 'shared/app/state/selectors/config';

import messages from './messages';

export const PartnershipHeader = ({ partnership }) => {
  const marriottJoinLink = useSelector(marriottJoinLinkSelector);
  const isDeltaAirlinesRequest = partnership?.id === 'deltaAirlines';
  const isMarriottRequest = partnership?.id === 'marriottLink';

  if (isDeltaAirlinesRequest) {
    return (
      <H1 className="text-bold text-center" size="lg">
        <FormattedMessage {...messages.createAccountDeltaAirlinesHeader} />
      </H1>
    );
  } else if (isMarriottRequest) {
    return (
      <React.Fragment>
        <H1 className="text-bold text-center" size="lg">
          <FormattedMessage {...messages.createAccountMarriottHeader} />
        </H1>
        <p className="mt3 md-text-center">
          <FormattedMessage
            {...rewardsMessages.joinMarriottRewards}
            values={{
              cta: (content) => (
                <ExternalLink href={marriottJoinLink}>{content}</ExternalLink>
              ),
            }}
          />
        </p>
      </React.Fragment>
    );
  }
};

export default PartnershipHeader;
